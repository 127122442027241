<template>

  <div class="personal">

    <router-view/>

  </div>

</template>

<script>
  export default {
    name : "personal"
  }
</script>

<style lang="scss" scoped>
  *{
    box-sizing:border-box;
  }
</style>